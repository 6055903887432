<template>
    <footer class="relative bg-gradient-pink text-white section-shadow">

        <!-- Decorative img -->
        <img src="@/assets/img/image187.png" alt="" class="hidden md:block absolute -top-14 right-0">
        <img src="@/assets/img/image228.png" alt="" class="hidden md:block absolute bottom-0 left-0">

        <div class="container mx-auto px-6 py-10">
            <div class="grid grid-cols-1 lg:grid-cols-5">

                <!-- Logo -->
                <div class="">
                    <a href="https://github.com/Learn-NEAR/NCD.L1.sample--meme-museum" target="_blank" class="flex">
                        <img src="@/assets/img/near_logo_stack_1.png" alt="" class="border-r-2 border-white">
                        <div class="ml-2">
                            <p class=" text-2xl font-bold tracking-tight">Memes</p>
                            <p class="-mt-2 font-josefin text-white text-2xl">museum</p>
                        </div>
                    </a>
                    <a href="mailto:alxndr.sai@gmail.com" class="mt-6 inline-block text-lg hover:text-blue-400">alxndr.sai@gmail.com</a>
                </div>

                <!-- About -->
                <div class="">
                    <h5 class="text-2xl font-bold mt-2">About</h5>
                    <ul class="mt-4 space-y-2 text-gray-200">
                        <li><a href="https://github.com/Learn-NEAR/NCD.L1.sample--meme-museum" target="_blank" class="hover:text-white">About contract</a></li>
                        <li><a href="https://near.org/" target="_blank" class="hover:text-white">About NEAR </a></li>
                        <li><a href="https://docs.near.org/docs/api/javascript-library" target="_blank" class="hover:text-white">NEAR-API-JS docs</a></li>
                    </ul>
                </div>

                <!-- Community -->
                <div class="">
                    <h5 class="text-2xl font-bold mt-2">Community</h5>
                    <ul class="mt-4 space-y-2 text-gray-200">
                        <li><a href="https://discord.gg/k4pxafjMWA" target="_blank" class="hover:text-white">Discord</a></li>
                        <li><a href="https://twitter.com/nearedu" target="_blank" class="hover:text-white">Twitter</a></li>
                    </ul>
                </div>

                <!-- Help -->
                <div class="">
                    <h5 class="text-2xl font-bold mt-2">Education</h5>
                    <ul class="mt-4 space-y-2 text-gray-200">
                        <li><a href="https://www.near.university/" target="_blank" class="hover:text-white">NEAR university</a></li>
                    </ul>
                </div>

                <!-- Footer button -->
                <div class="flex justify-center items-center">
                    <a href="#header" class="bg-white hover:bg-gray-200 text-blue-500 hover:text-blue-400 mt-12 py-3 rounded-md lg:mt-0 text-2xl font-bold border-md w-full text-center transform active:scale-95 duration-200">Jump to up</a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>